<template>
    <div class="public-container">
      <el-container>
        <el-header height="50px">
          <MenuSecond :listName="'customer'"></MenuSecond>
        </el-header>
        <!--  -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </div>
  </template>
  
  <script>
  import MenuSecond from "@/components/menu/menu-second.vue";
  export default {
    name: "StoreWebIndex",
  
    components: { MenuSecond },
  
    directives: {},
  
    data() {
      return {};
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style lang="less" scoped>
  .el-header {
    padding: 0;
    border-bottom: 1px solid #e5e9f2;
    background-color: #ffffff;
  }
  .el-main {
    height: calc(100vh - 60px);
    box-sizing: border-box;
    margin-top: 10px;
    background-color: #ffffff;
  }
  </style>
  